// eslint-disable-next-line import/no-unresolved
import { Discount } from '@Types/cart/Discount';
// eslint-disable-next-line import/no-unresolved
import { LineItem } from '@Types/cart/LineItem';
// eslint-disable-next-line import/no-unresolved
import { Money } from '@Types/product/Money';
// eslint-disable-next-line import/no-unresolved
import { PageQuery } from 'frontastic';
import { removeNonAlphanumericAndSpaces } from './skuHelpers';
import { CJ_BUSINESS_UNIT } from './utils/constant';

export const getCJPageType = (query: PageQuery) => {
  let pageType = 'homepage';
  if (query) {
    const { path } = query;
    const slugArray = path?.split('/');
    if (slugArray?.length > 0) {
      if (slugArray?.includes('categories') || slugArray?.includes('page')) {
        // first array element is blank that's why length is 3 instead of 2.
        if (slugArray?.length > 3) {
          pageType = 'subCategory';
        } else {
          pageType = 'category';
        }
      }
      if (slugArray?.includes('cart') || slugArray?.includes('checkout')) {
        pageType = 'cart';
      }
      if (slugArray?.includes('search')) {
        pageType = 'searchResults';
      }
      if (slugArray?.includes('products')) {
        pageType = 'productDetail';
      }
      if (slugArray?.includes('thank-you')) {
        pageType = 'conversionConfirmation';
      }
      if (slugArray?.includes('login')) {
        pageType = 'accountSignup';
      }
      if (slugArray?.includes('account')) {
        pageType = 'accountCenter';
      }
    }
  }
  return pageType;
};
export const getCJTagAndUrl = (args) => {
  const { account, cartData, pageType, recentOrder, cjeCookie, cjConfig, dataSources } = args;
  const cjTag = {
    enterpriseId: cjConfig?.enterpriseId,
    pageType: pageType,
    referringChannel: null,
  };
  let gtmCJTag = {};
  let cjUrl = cjConfig?.cjIframeSrcUrl;
  if (account) {
    const { accountId, emailHash } = account;
    cjTag['userId'] = accountId;
    cjTag['emailHash'] = emailHash;
  }
  if (pageType === 'cart' && cartData) {
    const { lineItems, subTotal } = cartData;
    const { items, cjParams } = getCJItems(lineItems);
    cjTag['items'] = items;
    cjTag['cartSubtotal'] = getPriceNumberUptoTwoDecimal(subTotal);
    cjUrl += cjParams;
  }
  if (pageType === 'conversionConfirmation' && recentOrder?.data) {
    let orderData: any;
    if (dataSources) {
      Object.entries(dataSources).map((data) => {
        if (Object.keys(data[1])[0] === 'order') {
          orderData = Object.values(data[1]);
        }
      });
    }
    const { orderNumber, lineItems, subTotal, discountTotal, discountCodes, createdAt } = orderData
      ? orderData[0]
      : recentOrder?.data;
    const { items, cjParams, products } = getCJItems(lineItems);
    const discountCoupon = accumalateDiscountCodes(discountCodes);
    const discount = getPriceNumberUptoTwoDecimal(discountTotal);
    let eventParam = '';
    if (cjeCookie) {
      eventParam = `&CJEVENT=${cjeCookie}`;
    }
    const cjOrderParams =
      eventParam +
      `&eventTime=${encodeURIComponent(createdAt)}&OID=${orderNumber}&currency=${
        subTotal?.currencyCode
      }&coupon=${discountCoupon}&discount=${discount}`;
    let cjAccountParams = '';

    cjTag['orderId'] = orderNumber;
    cjTag['actionTrackerId'] = cjConfig?.actionTrackerId;
    cjTag['currency'] = subTotal?.currencyCode;
    cjTag['amount'] = getPriceNumberUptoTwoDecimal(subTotal);
    cjTag['discount'] = discount;
    cjTag['coupon'] = discountCoupon;
    cjTag['cjeventOrder'] = cjeCookie ? cjeCookie : '';
    gtmCJTag = {
      subTotal: getPriceNumberUptoTwoDecimal(subTotal),
      coupon: discountCoupon,
      discount: discount,
      orderId: orderNumber,
      FIRECJ: cjeCookie ? cjeCookie : '',
      products: products,
    };
    if (account) {
      const { customerGroup, businessUnit } = account;
      let groupParams = '';
      if (customerGroup) {
        cjTag['customerSegment'] = customerGroup;
        groupParams = `&customerSegment=${customerGroup}`;
      }
      if (businessUnit) {
        cjTag['customerStatus'] = businessUnit?.status;
        cjTag['customerType'] = businessUnit?.unitType;
        cjTag['businessUnit'] = CJ_BUSINESS_UNIT;
        cjAccountParams =
          groupParams +
          `&customerStatus=${businessUnit?.status}&customerType=${businessUnit?.unitType}&businessUnit=${CJ_BUSINESS_UNIT}`;
      }
    }
    cjTag['items'] = items;
    cjUrl += cjOrderParams + cjParams + cjAccountParams;
  }
  return { cjTag, cjUrl, gtmCJTag };
};
const getCJItems = (lineItems: LineItem[]) => {
  const items = [];
  const products = [];
  let cjParams = '';
  if (lineItems?.length > 0) {
    lineItems?.map((item: LineItem, index: number) => {
      const { variant } = item;
      const unitPrice = getPriceNumberUptoTwoDecimal(item?.price);
      const discount = getPriceNumberUptoTwoDecimal(item?.appliedDiscountPrice);
      const cjItem = {
        itemId: removeNonAlphanumericAndSpaces(variant?.sku),
        unitPrice: unitPrice,
        quantity: item?.count,
        discount: discount * item?.count,
      };
      const product = {
        productTitle: item?.name,
        productType: item?.productType,
        //productBrand: '',
        productSku: removeNonAlphanumericAndSpaces(variant?.sku),
        productPrice: unitPrice,
        productQuantity: item?.count,
        productDiscount: discount,
      };
      const params = `&ITEM${index + 1}=${removeNonAlphanumericAndSpaces(variant?.sku)}&AMT${
        index + 1
      }=${unitPrice}&QTY${index + 1}=${item?.count}&DCNT${index + 1}=${discount * item?.count}`;
      cjParams += params;
      products?.push(product);
      items?.push(cjItem);
    });
  }
  return { items, cjParams, products };
};
const accumalateDiscountCodes = (discountCodes: Discount[]) => {
  let discountCode = '';
  if (discountCodes?.length > 0) {
    discountCodes?.map((discountCodeItem: Discount) => {
      discountCode = discountCode + ' ' + discountCodeItem?.code;
      return discountCode;
    });
  }
  return discountCode;
};
const getPriceNumberUptoTwoDecimal = (price: Money) => {
  const numericStringValue = price ? parseFloat((price?.centAmount / 100).toString()) : 0.0;
  return parseFloat(numericStringValue.toFixed(2));
};
