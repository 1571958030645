import { stringify } from 'querystring';
import React, { useEffect, useState } from 'react';
import { GetServerSideProps, Redirect } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { Product } from '@Types/product/Product';
import ServerCookies from 'cookies';
// eslint-disable-next-line import/no-unresolved
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getCJPageType, getCJTagAndUrl } from 'helpers/cjTagDataHelpers';
// eslint-disable-next-line import/no-unresolved
import { AUTHORIZE_NET_ACCEPT_LIBRARY } from 'helpers/constants/auth';
import { useFormat } from 'helpers/hooks/useFormat';
// eslint-disable-next-line import/no-unresolved
import { RouteHelpers } from 'helpers/routeHelpers';
import {
  createClient,
  ResponseError,
  LocaleStorage,
  useDarkMode,
  PageQuery,
  useAccount,
  useCart,
  useConfig,
} from 'frontastic';
// eslint-disable-next-line import/no-unresolved
import { FrontasticRenderer } from 'frontastic/lib/renderer';
// eslint-disable-next-line import/no-unresolved
import { tastics } from 'frontastic/tastics';
import { Log } from '../helpers/errorLogger';
import styles from './slug.module.css';

type SlugProps = {
  // This needs an overhaul. Can be too many things in my opinion (*Marcel)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // data: RedirectResponse | PageDataResponse | ResponseError | { ok: string; message: string } | string;
  locale: string;
  query: PageQuery;
  cjeCookie: string;
};

const SEOData = ({ pageProps, router, pageType, stringifyCJTag, stringifyGTMCJTag, authClientUrl, gaid }) => {
  const MASTER_DS_KEY = '__master';
  let seoTitle = '',
    seoMetaDescription = '',
    isProduct = false,
    product: Product = null;
  const dataSources = pageProps?.data?.dataSources;
  const pageFolderConfig = pageProps?.pageFolder?.configuration;
  if (dataSources) {
    const masterData = dataSources[MASTER_DS_KEY];
    if (masterData) {
      if (masterData?.blogCategory?.length > 0) {
        const seoData = masterData?.blogCategory[0]?.seo;
        seoTitle = seoData?.title;
        seoMetaDescription = seoData?.metaDescription;
      } else if (masterData[0]?.content?.allStaticPageSchema?.edges?.length > 0) {
        const seoData = masterData[0]?.content?.allStaticPageSchema?.edges[0]?.node?.seo;
        seoTitle = seoData?.title;
        seoMetaDescription = seoData?.metaDescription;
      } else if (masterData?.length > 0) {
        seoTitle = masterData[0]?.seo?.title;
        seoMetaDescription = masterData[0]?.seo?.metaDescription;
      } else if (masterData?.product) {
        seoTitle = masterData?.product?.metaTitle;
        seoMetaDescription = masterData?.product?.metaDescription;
        isProduct = true;
        product = masterData?.product as Product;
      } else if (masterData?.categoryData) {
        seoTitle = masterData?.categoryData?.metaTitle['en-US'];
        seoMetaDescription = masterData?.categoryData?.metaDescription['en-US'];
      }
    }
  }
  if (!seoMetaDescription || !seoTitle) {
    if (pageFolderConfig) {
      seoTitle = pageFolderConfig?.seoTitle?.en_US;
      seoMetaDescription = pageFolderConfig?.seoDescription?.en_US;
    }
  }

  const addGaSiteTag = () => {
    // if (document.getElementById('authSiteTag')) {
    //   return null; // Exit if authSiteTag is already present
    // }
    return (
      <script
        id="gaSiteTag"
        defer
        dangerouslySetInnerHTML={{
          __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', ${gaid}, { 'debug_mode':true });
    `,
        }}
      />
    );
  };
  const [seoVariant, setSeoVariant] = useState('');
  const appliedFiltersCount = RouteHelpers.getFilterCount(router?.asPath, router?.query?.sort, router?.query?.fbclid);

  useEffect(() => {
    const tempVariant = RouteHelpers.getSEOVariant(pageProps, router?.asPath);
    if (appliedFiltersCount === 1 && tempVariant !== 'true' && tempVariant !== 'false') {
      setSeoVariant(tempVariant);
    } else {
      setSeoVariant('');
    }
  }, [router.query, router.asPath]);

  return (
    <>
      <Head>
        <title>{seoVariant != '' && !router?.query?.q ? `${seoVariant + ' ' + seoTitle}` : seoTitle}</title>
        <meta name="description" content={seoMetaDescription} />
        <meta name="robots" content={appliedFiltersCount > 1 && !router?.query?.q ? 'none' : 'index, follow'} />
        {gaid && <Script src={gaid} strategy="afterInteractive" />}
        {/* {gaid && addGaSiteTag()} */}

        {/* Keeping the old appliedFiltesCount logic in until further instrucitons on how to handle query parameters is given */}
        <link
          rel="canonical"
          href={`${RouteHelpers.createCanonicalURL(pageProps, router?.asPath, isProduct || appliedFiltersCount > 1, [
            'sort',
            'fbclid',
          ])}`}
        />

        {isProduct && (
          <>
            <meta property="og:type" content="og:product" />
            <meta property="og:title" content={seoTitle} />
            <meta property="og:url" content={product?.navLink} />
            <meta property="og:description" content={seoMetaDescription} />
            <meta property="og:image" content={product?.variants[0]?.images[0]?.url} />
            <meta
              property="product:price:amount"
              content={(product?.variants[0]?.price?.centAmount / 100).toString()}
            />
            <meta property="product:price:currency" content={product?.variants[0]?.price?.currencyCode} />
          </>
        )}
        {pageType === 'conversionConfirmation' ? (
          <script id="cjConversionTag" defer type="application/json">
            {`
          if (!window.cj) window.cj = {};
            cj.order = ${'JSON.parse(' + "'" + stringifyCJTag + "'" + ')'};
        `}
          </script>
        ) : (
          <script id="cjSiteTag" defer type="application/json">
            {`
          if (!window.cj) window.cj = {};
            cj.sitePage = ${'JSON.parse(' + "'" + stringifyCJTag + "'" + ')'};
        `}
          </script>
        )}
        {pageType === 'conversionConfirmation' && (
          <script id="cjGTMTag" defer type="application/json">{`window.dataLayer = window.dataLayer || [];
          window.dataLayer?.push(${stringifyGTMCJTag})
          `}</script>
        )}
      </Head>
    </>
  );
};

export default function Slug({ data, locale, query, cjeCookie }: SlugProps) {
  LocaleStorage.locale = locale;
  const dataSources = data?.data?.dataSources;
  const { applyTheme } = useDarkMode();
  const pageType = getCJPageType(query);
  const { account } = useAccount();
  const router = useRouter();
  const { data: cartData, recentOrder } = useCart();
  const { data: config } = useConfig();
  const cjConfig = config?.cj;
  const authClientUrl = config?.authorizeNet?.authClientUrl;
  const gtm = config?.gtm;
  const { cjTag, cjUrl, gtmCJTag } = getCJTagAndUrl({
    pageType,
    account,
    cartData,
    recentOrder,
    cjeCookie,
    cjConfig,
    dataSources,
  });
  const stringifyCJTag = JSON?.stringify(cjTag);
  const stringifyGTMCJTag = JSON?.stringify(gtmCJTag);

  useEffect(() => {
    applyTheme(data?.pageFolder?.configuration?.theme);
  }, [applyTheme, data?.pageFolder?.configuration]);

  const { formatMessage } = useFormat({ name: 'common' });

  if (!data || typeof data === 'string') {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  if (!data?.ok && data?.message) {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data?.message}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  return (
    <>
      {
        <SEOData
          pageProps={data}
          router={router}
          pageType={pageType}
          stringifyCJTag={stringifyCJTag}
          stringifyGTMCJTag={stringifyGTMCJTag}
          authClientUrl={authClientUrl}
          gaid={gtm?.gaid}
        />
      }
      {pageType === 'conversionConfirmation' && (
        <iframe height="1" width="1" className="hidden" name="cj_conversion" src={cjUrl}></iframe>
      )}
      <FrontasticRenderer data={data} tastics={tastics} wrapperClassName={styles.gridWrapper} />
    </>
  );
}

const setCJECookie = (query, req, res) => {
  if (query?.cjevent && query?.cjevent?.trim().length > 0) {
    res.setHeader('Set-Cookie', [
      `cje=${query?.cjevent}; Path=/; Domain=.${req.headers['host']?.split(':')?.[0]}; Max-Age:${86400 * 395}`,
    ]);
  }
};
export const getServerSideProps: GetServerSideProps | Redirect = async ({ params, locale, query, req, res }) => {
  LocaleStorage.locale = locale;
  const frontastic = createClient();
  const data = await frontastic.getRouteData(params, locale, query, req, res);
  setCJECookie(query, req, res);
  const cookies = new ServerCookies(req, res);
  const cjeCookie = cookies?.get('cje');
  if (data) {
    const host = req.headers['host'] ?? 'localhost:3000';
    data['hostName'] = host;
    data['scheme'] = host?.includes('localhost') ? 'http://' : 'https://';
    if (data instanceof ResponseError && data.getStatus() == 404) {
      return {
        notFound: true,
      };
    } else if (typeof data === 'object' && 'target' in data) {
      return {
        redirect: {
          destination: data.target,
          statusCode: data.statusCode,
        } as Redirect,
      };
    }
  }

  if (data instanceof Error) {
    // @TODO: Render nicer error page in debug mode, which shows the error to
    // the developer and also outlines how to debug this (take a look at
    // frontastic-CLI).
    Log.error('Error retrieving data: ', data);
    return {
      notFound: true,
    };
  }

  if (typeof data === 'string') {
    return {
      props: {
        data: { error: data },
        error: data,
      },
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((data as any)?.message === 'Could not resolve page from path') {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      data: data || null,
      locale: locale,
      query: query,
      cjeCookie: cjeCookie || query?.cjevent || '',
      ...(await serverSideTranslations(locale, [
        'common',
        'cart',
        'product',
        'checkout',
        'account',
        'error',
        'success',
        'wishlist',
        'newsletter',
      ])),
    },
  };
};
